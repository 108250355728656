import * as serializers from '@/constants/serializer';
import * as schemaItemTypes from './schemaItemTypes';
import { rules } from './validationRules';

const linkedProperties = {
  PROJECT: 'project',
  UNIT: 'parentUnit',
};

/**
 * @type {Array<import('./schema').default>}
 */
export const schema = [
  {
    type: schemaItemTypes.ROW,
    children: [
      {
        label: 'room.name',
        prop: 'header',
        type: schemaItemTypes.STRING,
        size: 3,
      },
      {
        label: 'room.apartment',
        prop: linkedProperties.UNIT,
        type: schemaItemTypes.UNIT,
        size: 3,
      },
      {
        label: 'room.total_area',
        prop: 'internalArea',
        type: schemaItemTypes.SQUARE,
        size: 3,
        payload: [
          {
            param: 'areaUnit',
            from: [linkedProperties.PROJECT, 'areaUnit'],
          },
        ],
      },
      {
        label: 'unit.rent_amount',
        prop: 'goalRentCost',
        type: schemaItemTypes.AMOUNT,
        rules: [rules.REQUIRED, rules.POSITIVE],
        size: 3,
        payload: [
          {
            param: 'currencySymbol',
            from: [linkedProperties.PROJECT, 'currency', 'symbol'],
          },
        ],
      },
    ],
  },
  {
    label: 'unit.status',
    type: schemaItemTypes.TITLED_SECTION_WITH_STATUS,
    payload: [
      {
        param: 'status',
        from: ['status'],
        serializer: serializers.CAPITALIZE_FIRST_LETTER,
      },
    ],
    children: [
      {
        type: schemaItemTypes.ROW,
        children: [
          {
            type: schemaItemTypes.CONTRACTS,
            size: 8,
            isRoom: true,
            payload: [
              {
                param: 'usageId',
                from: ['id'],
              },
            ],
          },
        ],
      },
    ],
  },
];

export { schema as default };
