<template>
  <div>
    <entity-header
      class="mb-10"
      :title="room.name"
      :is-archived="room.isArchived"
      :back-route="backRoute"
      :edit-route="editRoute"
      :can-update="canUpdateProjects"
      @archive="archive"
    />

    <div v-if="isLoading" class="d-flex justify-center primary--text">
      <v-progress-circular indeterminate />
    </div>
    <view-builder v-else :schema="$options.schema" :data="room" />
  </div>
</template>

<script>
// Services
import realtyService from '@/services/realty';
import { normalizeRoom } from '@/services/serializations';
import analyticsService from '@/services/analytics';
import ViewBuilderService from '@/services/viewBuilder';

// Constants
import { PROJECTS_ARCHIVE, PROJECTS, ROOMS_EDIT } from '@/constants/routes';
import { ROOM_VIEWING, ARCHIVE_ROOM, UNARCHIVE_ROOM } from '@/constants/analyticsActions';
import { PROJECTS as PROJECTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';

import ViewBuilder from '@/components/schema/ViewBuilder.vue';
import EntityHeader from '@/components/EntityHeader.vue';

// Schema
import { schema } from '@/schemas/room.view.schema';

export default {
  unsubscribe: null,

  name: 'RoomsDetailed',

  components: { ViewBuilder, EntityHeader },

  data() {
    return {
      isLoading: false,
      room: {},
      id: this.$route.params.id,
    };
  },

  computed: {
    canUpdateProjects() {
      return this.$can(UPDATE, PROJECTS_SUBJECT);
    },

    backRoute() {
      if (this.$route.params.prevPageRoute) return this.$route.params.prevPageRoute;

      const routeName = this.room.isArchived ? PROJECTS_ARCHIVE : PROJECTS;

      return { name: routeName, query: { page: this.$route.query.prevPage || 1 } };
    },

    editRoute() {
      return { name: ROOMS_EDIT, params: { id: this.id } };
    },
  },

  mounted() {
    analyticsService.track(ROOM_VIEWING);
    this.fetchRoom();

    this.$options.unsubscribe = ViewBuilderService.subscribe('archive-usage', () => this.fetchRoom());
  },

  beforeDestroy() {
    this.$options.unsubscribe();
  },

  methods: {
    archive() {
      analyticsService.track(this.isArchived ? UNARCHIVE_ROOM : ARCHIVE_ROOM);
      realtyService.archiveRoom(this.id, !this.room.isArchived).then(() => {
        this.room.isArchived = !this.room.isArchived;
      });
    },

    fetchRoom() {
      this.isLoading = true;

      realtyService
        .getRoomById(this.$route.params.id)
        .then(room => {
          this.room = normalizeRoom(room);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },

  schema,
};
</script>
